import { useRouter } from 'next/router'

const Home = () => {
  const router = useRouter()

  router.push('/info')

  return null
}

export default Home
